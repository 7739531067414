import { defineComponent, ref, computed, watch, reactive, onMounted } from '@vue/composition-api';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { getExample } from 'awesome-phonenumber';
import utils, { getCountry, setCaretPosition } from '@/uikit/PhoneNumber/TelephoneField/utils';
function getDefault(key) {
    const value = utils.options[key];
    if (typeof value === 'undefined') {
        return utils.options[key];
    }
    return value;
}
// Polyfill for Event.path in IE 11: https://stackoverflow.com/a/46093727
function getParents(node, memo) {
    const parsedMemo = memo || [];
    const { parentNode } = node;
    if (!parentNode) {
        return parsedMemo;
    }
    return getParents(parentNode, parsedMemo.concat(parentNode));
}
export default defineComponent({
    name: 'VueTelInput',
    directives: {
        // Click-outside by BosNaufal: https://github.com/BosNaufal/vue-click-outside
        'click-outside': {
            bind(el, binding, vNode) {
                // Provided expression must evaluate to a function.
                if (typeof binding.value !== 'function') {
                    const compName = vNode.context.name;
                    let warn = `[Vue-click-outside:] provided expression ${binding.expression} is not a function, but has to be`;
                    if (compName) {
                        warn += `Found in component ${compName}`;
                    }
                    console.warn(warn);
                }
                // Define Handler and cache it on the element
                const { bubble } = binding.modifiers;
                const handler = e => {
                    // Fall back to composedPath if e.path is undefined
                    const path = e.path ||
                        (e.composedPath ? e.composedPath() : false) ||
                        getParents(e.target);
                    if (bubble ||
                        (path.length && !el.contains(path[0]) && el !== path[0])) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;
                // add Event Listeners
                document.addEventListener('click', handler);
            },
            unbind(el) {
                // Remove Event Listeners
                document.removeEventListener('click', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            }
        }
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: () => getDefault('placeholder')
        },
        disabledFetchingCountry: {
            type: Boolean,
            default: () => getDefault('disabledFetchingCountry')
        },
        disabled: {
            type: Boolean,
            default: () => getDefault('disabled')
        },
        disabledFormatting: {
            type: Boolean,
            default: () => getDefault('disabledFormatting')
        },
        mode: {
            type: String,
            default: () => getDefault('mode')
        },
        invalidMsg: {
            type: String,
            default: () => getDefault('invalidMsg')
        },
        required: {
            type: Boolean,
            default: () => getDefault('required')
        },
        allCountries: {
            type: Array,
            default: () => getDefault('allCountries')
        },
        defaultCountry: {
            // Default country code, ie: 'AU'
            // Will override the current country of user
            type: String,
            default: () => getDefault('defaultCountry')
        },
        enabledCountryCode: {
            type: Boolean,
            default: () => getDefault('enabledCountryCode')
        },
        enabledFlags: {
            type: Boolean,
            default: () => getDefault('enabledFlags')
        },
        preferredCountries: {
            type: Array,
            default: () => getDefault('preferredCountries')
        },
        onlyCountries: {
            type: Array,
            default: () => getDefault('onlyCountries')
        },
        ignoredCountries: {
            type: Array,
            default: () => getDefault('ignoredCountries')
        },
        autocomplete: {
            type: String,
            default: () => getDefault('autocomplete')
        },
        autofocus: {
            type: Boolean,
            default: () => getDefault('autofocus')
        },
        name: {
            type: String,
            default: () => getDefault('name')
        },
        wrapperClasses: {
            type: [String, Array, Object],
            default: () => getDefault('wrapperClasses')
        },
        inputClasses: {
            type: [String, Array, Object],
            default: () => getDefault('inputClasses')
        },
        inputId: {
            type: String,
            default: () => getDefault('inputId')
        },
        dropdownOptions: {
            type: Object,
            default: () => getDefault('dropdownOptions')
        },
        inputOptions: {
            type: Object,
            default: () => getDefault('inputOptions')
        },
        maxLen: {
            type: Number,
            default: () => getDefault('maxLen')
        },
        validCharactersOnly: {
            type: Boolean,
            default: () => getDefault('validCharactersOnly')
        },
        dynamicPlaceholder: {
            type: Boolean,
            default: () => getDefault('dynamicPlaceholder')
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const finishMounted = ref(false);
        const activeCountry = reactive({ iso2: '', dialCode: '' });
        const phone = ref('');
        const selectedIndex = ref(null);
        const open = ref(false);
        const cursorPosition = ref(0);
        const typeToFindInput = ref('');
        const typeToFindTimer = ref(null);
        const input = ref(null);
        const list = ref(null);
        const parsedPlaceholder = computed(() => {
            if (!finishMounted.value) {
                return '';
            }
            if (props.dynamicPlaceholder) {
                const mode = props.mode || 'international';
                return getExample(activeCountry.iso2, 'mobile').getNumber(mode);
            }
            return props.placeholder;
        });
        const parsedMode = computed(() => {
            if (props.mode) {
                if (!['international', 'national'].includes(props.mode)) {
                    console.error('Invalid value of prop "mode"');
                }
                else {
                    return props.mode;
                }
            }
            if (!phone.value || phone.value[0] !== '+') {
                return 'national';
            }
            return 'international';
        });
        /**
         * Get the list of countries from the list of iso2 code
         */
        const getCountries = (list = []) => {
            return list
                .map(countryCode => this.findCountry(countryCode))
                .filter(Boolean);
        };
        const filteredCountries = computed(() => {
            // List countries after filtered
            if (props.onlyCountries.length) {
                return getCountries(props.onlyCountries);
            }
            if (props.ignoredCountries.length) {
                return props.allCountries.filter(({ iso2 }) => !props.ignoredCountries.includes(iso2.toUpperCase()) &&
                    !props.ignoredCountries.includes(iso2.toLowerCase()));
            }
            return props.allCountries;
        });
        const sortedCountries = computed(() => {
            // Sort the list countries: from preferred countries to all countries
            const _preferredCountries = getCountries(props.preferredCountries).map(country => ({ ...country, preferred: true }));
            return [..._preferredCountries, ...filteredCountries.value];
        });
        const phoneObject = computed(() => {
            const result = parsePhoneNumber(phone.value, {
                regionCode: activeCountry.iso2
            });
            Object.assign(result, {
                isValid: result.valid,
                country: activeCountry
            });
            return result;
        });
        const phoneText = computed(() => {
            let key = 'input';
            if (phoneObject.value.valid) {
                key = parsedMode.value;
            }
            return phoneObject.value.number[key] || '';
        });
        const findCountry = (iso = '') => {
            return props.allCountries.find(country => country.iso2 === iso.toUpperCase());
        };
        const getItemClass = (index, iso2) => {
            const highlighted = selectedIndex.value === index;
            const lastPreferred = index === props.preferredCountries.length - 1;
            const preferred = props.preferredCountries.some(c => c.toUpperCase() === iso2);
            return {
                highlighted,
                'last-preferred': lastPreferred,
                preferred
            };
        };
        const testCharacters = () => {
            const re = /^[()\-+0-9\s]*$/;
            return re.test(phone.value);
        };
        const onBlur = () => {
            emit('blur');
            emit('onBlur'); // Deprecated
        };
        const onEnter = () => {
            emit('enter');
            emit('onEnter'); // Deprecated
        };
        const onSpace = () => {
            emit('space');
            emit('onSpace'); // Deprecated
        };
        const toggleDropdown = () => {
            if (props.disabled) {
                return;
            }
            open.value = !open.value;
        };
        const clickedOutside = () => {
            open.value = false;
        };
        const reset = () => {
            selectedIndex.value = sortedCountries.value
                .map(c => c.iso2)
                .indexOf(activeCountry.iso2);
            open.value = false;
        };
        const onInput = (e) => {
            // Get the current value of the input
            let value = e.target.value;
            // Replace any non-digit or nonplus sign characters with an empty string
            value = value.replace(/[^0-9+]/g, '');
            // Update the input value with the cleaned up value
            e.target.value = value;
            phone.value = value;
            if (props.validCharactersOnly && !testCharacters()) {
                return;
            }
            input.value.setCustomValidity(phoneObject.value.valid ? '' : props.invalidMsg);
            // Returns response.number to assign it to v-model (if being used)
            // Returns full response for cases @input is used
            // and parent wants to return the whole response.
            emit('input', phoneText.value, phoneObject.value);
            emit('onInput', phoneObject.value); // Deprecated
            // Keep the current cursor position just in case the input reformatted,
            // and it gets moved to the last character.
            if (e && e.target) {
                cursorPosition.value = e.target.selectionStart;
            }
        };
        const choose = (country, toEmitInputEvent = false) => {
            let parsedCountry = country;
            if (typeof parsedCountry === 'string') {
                parsedCountry = findCountry(parsedCountry);
            }
            if (!parsedCountry) {
                return;
            }
            Object.assign(activeCountry, parsedCountry || activeCountry || {});
            if (phone.value &&
                phone.value[0] === '+' &&
                activeCountry.iso2 &&
                phoneObject.value.number.national) {
                // Attach the current phone number with the newly selected country
                phone.value = parsePhoneNumber(phoneObject.value.number.national, {
                    regionCode: activeCountry.iso2
                }).number?.international.replace(/\s/g, '');
            }
            else if (props.inputOptions &&
                props.inputOptions.showDialCode &&
                parsedCountry) {
                // Reset phone if the showDialCode is set
                phone.value = `+${parsedCountry.dialCode}`;
            }
            if (toEmitInputEvent) {
                emit('input', phoneText.value, phoneObject.value);
                emit('onInput', phoneObject.value); // Deprecated
            }
        };
        const initializeCountry = () => {
            return new Promise(resolve => {
                /**
                 * 1. If the phone included prefix (+12), try to get the country and set it
                 */
                if (phone.value && phone.value[0] === '+') {
                    const _activeCountry = parsePhoneNumber(phone.value).regionCode;
                    if (_activeCountry) {
                        choose(_activeCountry);
                        resolve();
                        return;
                    }
                }
                /**
                 * 2. Use default country if passed from parent
                 */
                if (props.defaultCountry) {
                    const _defaultCountry = findCountry(props.defaultCountry);
                    if (_defaultCountry) {
                        choose(_defaultCountry);
                        resolve();
                        return;
                    }
                }
                const fallbackCountry = findCountry(props.preferredCountries[0]) ||
                    filteredCountries.value[0];
                /**
                 * 3. Check if fetching country based on user's IP is allowed, set it as the default country
                 */
                if (!props.disabledFetchingCountry) {
                    getCountry()
                        .then(res => {
                        Object.assign(activeCountry, findCountry(res) || activeCountry);
                    })
                        .catch(error => {
                        console.warn(error);
                        /**
                         * 4. Use the first country from preferred list (if available) or all countries list
                         */
                        choose(fallbackCountry);
                    })
                        .finally(() => {
                        resolve();
                    });
                }
                else {
                    /**
                     * 4. Use the first country from preferred list (if available) or all countries list
                     */
                    choose(fallbackCountry);
                    resolve();
                }
            });
        };
        const keyboardNav = e => {
            if (e.keyCode === 40) {
                // down arrow
                e.preventDefault();
                open.value = true;
                if (!selectedIndex.value) {
                    selectedIndex.value = 0;
                }
                else {
                    selectedIndex.value = Math.min(sortedCountries.value.length - 1, selectedIndex.value + 1);
                }
                const selEle = list.value.children[selectedIndex.value];
                if (selEle.offsetTop + selEle.clientHeight >
                    list.value.scrollTop + list.value.clientHeight) {
                    list.value.scrollTop =
                        selEle.offsetTop - list.value.clientHeight + selEle.clientHeight;
                }
            }
            else if (e.keyCode === 38) {
                // up arrow
                e.preventDefault();
                open.value = true;
                if (!selectedIndex.value) {
                    selectedIndex.value = sortedCountries.value.length - 1;
                }
                else {
                    selectedIndex.value = Math.max(0, selectedIndex.value - 1);
                }
                const selEle = list.value.children[selectedIndex.value];
                if (selEle.offsetTop < list.value.scrollTop) {
                    list.value.scrollTop = selEle.offsetTop;
                }
            }
            else if (e.keyCode === 13) {
                // enter key
                if (!selectedIndex.value) {
                    choose(sortedCountries.value[selectedIndex.value]);
                }
                open.value = !open.value;
            }
            else {
                // typing a country's name
                typeToFindInput.value += e.key;
                clearTimeout(typeToFindTimer.value);
                typeToFindTimer.value = setTimeout(() => {
                    typeToFindInput.value = '';
                }, 700);
                // don't include preferred countries so we jump to the right place in the alphabet
                const typedCountryI = sortedCountries.value
                    .slice(props.preferredCountries.length)
                    .findIndex(c => c.name.toLowerCase().startsWith(typeToFindInput.value));
                if (typedCountryI >= 0) {
                    selectedIndex.value = props.preferredCountries.length + typedCountryI;
                    const selEle = list.value.children[selectedIndex.value];
                    const needToScrollTop = selEle.offsetTop < list.value.scrollTop;
                    const needToScrollBottom = selEle.offsetTop + selEle.clientHeight >
                        list.value.scrollTop + list.value.clientHeight;
                    if (needToScrollTop || needToScrollBottom) {
                        list.value.scrollTop =
                            selEle.offsetTop - list.value.clientHeight / 2;
                    }
                }
            }
        };
        watch(() => props.value, () => {
            if (props.value) {
                phone.value = props.value.trim();
            }
        }, { immediate: true });
        watch(() => open.value, () => {
            emit(open.value ? 'open' : 'close');
        }, { immediate: true });
        watch(() => activeCountry, () => {
            if (activeCountry && activeCountry.iso2) {
                emit('country-changed', activeCountry);
            }
        }, { immediate: true });
        watch(() => phone.value, (newValue, oldValue) => {
            if (props.validCharactersOnly && !testCharacters()) {
                root.$nextTick(() => {
                    phone.value = oldValue;
                });
            }
            else if (newValue) {
                if (newValue[0] === '+') {
                    const code = parsePhoneNumber(newValue).regionCode;
                    if (code) {
                        Object.assign(activeCountry, findCountry(code) || activeCountry);
                    }
                }
            }
            // Reset the cursor to current position if it's not the last character.
            if (oldValue && cursorPosition.value < oldValue.length) {
                root.$nextTick(() => {
                    setCaretPosition(input.value, cursorPosition.value);
                });
            }
        }, { immediate: true });
        watch(() => phoneObject.value.valid, _value => {
            if (_value) {
                phone.value = phoneText.value.replace(/\s/g, '');
            }
            emit('validate', phoneObject.value);
            emit('onValidate', phoneObject.value); // Deprecated
        }, { immediate: true });
        onMounted(() => {
            if (props.autofocus) {
                input.value.focus();
            }
            initializeCountry()
                .then(() => {
                if (!phone.value &&
                    props.inputOptions &&
                    props.inputOptions.showDialCode &&
                    activeCountry.dialCode) {
                    phone.value = `+${activeCountry.dialCode}`;
                }
                emit('validate', phoneObject.value);
                emit('onValidate', phoneObject.value); // Deprecated
            })
                .catch(console.error)
                .finally(() => {
                finishMounted.value = true;
            });
        });
        return {
            activeCountry,
            open,
            input,
            list,
            parsedPlaceholder,
            phone,
            sortedCountries,
            choose,
            clickedOutside,
            getItemClass,
            keyboardNav,
            onBlur,
            onEnter,
            onInput,
            onSpace,
            reset,
            toggleDropdown
        };
    }
});
