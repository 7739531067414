import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import PhoneValidator from '@/uikit/PhoneNumber/PhoneValidator.vue';
import VueTelInput from '@/uikit/PhoneNumber/TelephoneField.vue';
// We are using version 4.0.0
// https://github.com/iamstevendao/vue-tel-input/releases/tag/v4.0.0
// After importing the component to the project, we already saved 400KB in chunk-vendors.[hash].js
// Do we still need this? "@types/vue-tel-input": "^2.1.1",
export default defineComponent({
    components: {
        VueTelInput,
        PhoneValidator
    },
    props: {
        value: {
            type: [Object, String],
            default: () => { }
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Phone number'
        },
        label: {
            type: String,
            default: ''
        },
        error: {
            type: [String, Object],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        append: {
            type: String,
            default: ''
        },
        validate: {
            type: Boolean,
            default: false
        },
        mobile: {
            type: Boolean,
            default: false
        },
        autofocused: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const phoneNumber = ref('');
        const regionCode = ref('NL');
        const phoneToValidate = ref({});
        const hasPhoneToBeValidated = ref(false);
        const error = ref('');
        const errorMessage = computed(() => props.error ? props.error : error.value);
        const phoneData = () => {
            return {
                formatted_number: phoneNumber.value,
                region_code: regionCode.value,
                phone_usage_type: props.mobile ? 'LOGIN' : 'GENERAL'
            };
        };
        const onInput = (phone) => {
            phoneNumber.value = phone?.number.e164;
            regionCode.value = phone?.regionCode;
            emit('input', phoneData());
        };
        const phoneValidated = (isPhoneValid) => {
            error.value = isPhoneValid
                ? ''
                : root.$i18n.t('common.errors.phone.validation').toString();
            emit('validated', isPhoneValid);
        };
        watch(() => props.value, () => {
            if (typeof props.value === 'string') {
                root.$nextTick(() => {
                    phoneNumber.value = props.value;
                });
            }
            else if (typeof props.value === 'object') {
                phoneNumber.value = props.value.formatted_number;
                if (props.value.region_code) {
                    regionCode.value = props.value.region_code;
                }
            }
            error.value = '';
        }, { immediate: true });
        watch(() => props.validate, () => {
            if (props.validate) {
                phoneToValidate.value = phoneData();
            }
            hasPhoneToBeValidated.value = props.validate;
        }, { immediate: true });
        return {
            phoneNumber,
            regionCode,
            phoneToValidate,
            hasPhoneToBeValidated,
            errorMessage,
            onInput,
            phoneValidated
        };
    }
});
