import { defineComponent } from '@vue/composition-api';
import { usePhoneValidityQuery } from '@/generated-types/graphql.types';
export default defineComponent({
    props: {
        phone: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, { emit }) {
        const { onResult } = usePhoneValidityQuery(() => ({ input: props.phone }));
        onResult(result => {
            const isPhoneValid = result.data?.phone_validity.is_valid;
            emit('validated', isPhoneValid);
        });
        return {};
    }
});
